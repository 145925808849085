import React from "react";

const ConstruirLares = () => {
  return (
    <div className="lares-section-wrapper">
      <h1>
        CONSTRUIR LARES, REALIZAR SONHOS
      </h1>
      <p>Juntos transformamos casas em lares.</p>
    </div>
  );
};

export default ConstruirLares;
